<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="heading">
          Event Timeline
          <div class="hLine"></div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row v-if="$vuetify.breakpoint.smAndUp">
         
          <v-col cols="2" class="text-center">
            <img
              :src="getImgSrc(kickOff ? 'kickOff.gif' : 'kickOff.png')"
              alt=""
              :class="[kickOff ? 'kickGifClass' : 'kickPngClass']"
            />
            <div :class="[kickOff ? 'eventTitleActive' : 'eventTitle']">
              Kick Off
            </div>
            <v-menu
              offset-y
              open-on-hover
              auto
              nudge-bottom="15"
              close-delay="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  :class="[kickOff ? 'eventDateActive' : 'eventDate']"
                  style="max-width: 80px; margin: auto"
                >
                  {{ getdates.kickOff }}
                </div>
              </template>
              <v-btn
                class="text-capitalize px-2"
                style="
                  border: 1px solid #512e96;
                  box-sizing: border-box;
                  border-radius: 6px;
                "
                @click="addCalendar(getdates.kickOffStr, 'Kick Off day')"
              >
                <span style="color: #512e96; letter-spacing: 0px"
                  >add to calendar</span
                >
              </v-btn>
            </v-menu>
          </v-col>
          <v-col cols="2" class="text-center pt-11">
            <img
              :src="getImgSrc(goldBall ? 'goldBall.gif' : 'goldBall.png')"
              alt=""
              :class="[goldBall ? 'goldGifClass' : 'goldPngClass']"
            />
            <div :class="[goldBall ? 'eventTitleActive' : 'eventTitle']">
              Platinum Ball Giveaway
            </div>
            <v-menu
              offset-y
              open-on-hover
              auto
              nudge-bottom="15"
              nudge-right="0"
              close-delay="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  :class="[goldBall ? 'eventDateActive' : 'eventDate']"
                  style="max-width: 80px; margin: auto"
                >
                  {{ getdates.goldBall }}
                </div>
              </template>
              <v-btn
                class="text-capitalize px-2"
                style="
                  border: 1px solid #512e96;
                  box-sizing: border-box;
                  border-radius: 6px;
                "
                @click="addCalendar(getdates.goldBallStr, 'Gold Ball Giveaway')"
              >
                <span style="color: #512e96; letter-spacing: 0px"
                  >add to calendar</span
                >
              </v-btn>
            </v-menu>
          </v-col>
          <v-col cols="2" class="text-center pt-11">
            <img
              :src="getImgSrc(graduation ? 'graduation.gif' : 'graduation.png')"
              :class="[graduation ? 'gifClass' : 'graduationPngClass']"
              alt=""
            />
            <div :class="[graduation ? 'eventTitleActive' : 'eventTitle']">
              Graduation
            </div>
            <v-menu
              offset-y
              open-on-hover
              auto
              nudge-bottom="15"
              close-delay="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  :class="[graduation ? 'eventDateActive' : 'eventDate']"
                  style="max-width: 80px; margin: auto"
                >
                  {{ getdates.grad }}
                </div>
              </template>
              <v-btn
                class="text-capitalize px-2 px-2"
                style="
                  border: 1px solid #512e96;
                  box-sizing: border-box;
                  border-radius: 6px;
                "
                @click="
                  addCalendar(getdates.gradStr, 'Graduation Celebration Day')
                "
              >
                <span style="color: #512e96; letter-spacing: 0px"
                  >add to calendar</span
                >
              </v-btn>
            </v-menu>
          </v-col>
          <v-col cols="2" class="text-center">
            <img
              :src="getImgSrc(endDate ? 'eventEnd.gif' : 'eventEnd.png')"
              alt=""
              :class="[endDate ? 'endGifClass' : 'endPngClass']"
            />
            <div :class="[endDate ? 'eventTitleActive' : 'eventTitle']">
              Donations  <br />
              Close
            </div>
            <v-menu
              open-on-hover
              offset-y
              auto
              nudge-bottom="15"
              close-delay="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  :class="[endDate ? 'eventDateActive' : 'eventDate']"
                  style="max-width: 80px; margin: auto"
                >
                  {{ getdates.eventEnd }}
                </div>
              </template>
              <v-btn
                class="text-capitalize px-2 px-2"
                style="border: 1px solid #512e96; border-radius: 6px"
                @click="addCalendar(getdates.eventEndStr, 'Event Ends')"
              >
                <span style="color: #512e96; letter-spacing: 0px"
                  >add to calendar</span
                >
              </v-btn>
            </v-menu>
          </v-col>
          <v-col
            cols="2"
            sm="10"
            :class="$vuetify.breakpoint.smAndUp ? 'py-8 pt-15' : ''"
          >
            <!-- <v-progress-linear
              :value="50"
              color="#4A3290"
              height="15"
              style="border-radius: 20px"
            ></v-progress-linear> -->
            <div class="slidecontainer">
              <input
                :style="sliderColor"
                type="range"
                min="1"
                max="100"
                :value="value"
                class="slider"
                id="myRange"
                disabled
              />
            </div>
          </v-col>
        </v-row>
        <v-row v-else class="px-0">
          <v-col cols="10" class="px-0">
            <v-row justify="center">
              <v-col cols="3">
                <img
                  :src="getImgSrc(kickOff ? 'kickOff.gif' : 'kickOff.png')"
                  alt=""
                  :class="[kickOff ? 'kickGifClass' : 'kickPngClass']"
                />
              </v-col>
              <v-col cols="4" class="text-center py-6">
                <div :class="[kickOff ? 'eventTitleActive' : 'eventTitle']">
                  Kick Off
                </div>
              </v-col>
              <v-col cols="3" class="px-0 py-6 text-center">
                <div :class="[kickOff ? 'eventDateActive' : 'eventDate']">
                  {{ getdates.kickOff }}
                </div>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="3">
                <img
                  :src="getImgSrc(goldBall ? 'goldBall.gif' : 'goldBall.png')"
                  alt=""
                  :class="[goldBall ? 'goldGifClass' : 'goldPngClass']"
                />
              </v-col>
              <v-col cols="4" class="text-center">
                <div :class="[goldBall ? 'eventTitleActive' : 'eventTitle']">
                  Platinum Ball Giveaway
                </div>
              </v-col>
              <v-col cols="3" class="px-0 py-6 text-center">
                <div :class="[goldBall ? 'eventDateActive' : 'eventDate']">
                  {{ getdates.goldBall }}
                </div>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="3">
                <img
                  :src="
                    getImgSrc(graduation ? 'graduation.gif' : 'graduation.png')
                  "
                  :class="[
                    graduation ? 'graduationGifClass' : 'graduationPngClass',
                  ]"
                  alt=""
                />
              </v-col>
              <v-col cols="4" class="text-center">
                <div :class="[graduation ? 'eventTitleActive' : 'eventTitle']">
                  Graduation Celebration
                </div>
              </v-col>
              <v-col cols="3" class="px-0 py-6 text-center">
                <div :class="[graduation ? 'eventDateActive' : 'eventDate']">
                  {{ getdates.grad }}
                </div>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="3">
                <img
                  :src="getImgSrc(endDate ? 'eventEnd.gif' : 'eventEnd.png')"
                  alt=""
                  :class="[endDate ? 'endGifClass' : 'endPngClass']"
                />
              </v-col>
              <v-col cols="4" class="text-center">
                <div :class="[endDate ? 'eventTitleActive' : 'eventTitle']">
                  Event Ends
                </div>
              </v-col>
              <v-col cols="3" class="px-0 py-6 text-center">
                <div :class="[endDate ? 'eventDateActive' : 'eventDate']">
                  {{ getdates.eventEnd }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2" class="fill-height px-0 pt-5">
            <div class="slidecontainer">
              <input
                type="range"
                min="1"
                max="100"
                :value="value"
                class="slider"
                id="myRange"
                disabled
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!--<v-row>
      <v-col cols="12" :class="$vuetify.breakpoint.smAndUp ? 'py-10' : ''">
        <video
          @click="openVideo"
          style="cursor: pointer"
          :width="$vuetify.breakpoint.smAndUp ? '90%' : '100%'"
          height="100%"
          class="IntroVideo"
          poster="@/assets/publicDonationPage/timelineEvent/intro.jpg"
        >
          <source :src="getVideo" />
        </video>

        <img
          src="@/assets/play.png"
          alt=""
          :width="$vuetify.breakpoint.smAndUp ? '70' : '50'"
          :height="$vuetify.breakpoint.smAndUp ? '70' : '50'"
          @click="openVideo"
          :style="
            $vuetify.breakpoint.smAndUp
              ? 'position: relative; bottom: 40%; right: 45%; cursor: pointer'
              : 'position:relative;bottom:60%;left:48%'
          "
        />
      </v-col>
    </v-row>
    <full-screen-video-viewer
      :imageViewer="imageViewer"
      v-if="imageViewer.flag"
    ></full-screen-video-viewer>-->
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
// import VueVideoThumbnail from "vue-video-thumbnail";
export default {
  name: "EventTimeline",
  components: {
    // VideoPlayer: () => import("@/components/VideoPlayer/VideoPlayer"),
    // VueVideoThumbnail,
    // FullScreenVideoViewer: () =>
    //   import("@/components/VideoPlayer/FullScreenImageViewer"),
  },
  data() {
    return {
      src: "",
      startDate: false,
      kickOff: false,
      goldBall: false,
      graduation: false,
      endDate: false,
      imageViewer: {
        src: "",
        flag: false,
        type: "video",
      },
      value: 4,
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    sliderColor() {
      if (this.value > 40) {
        return {
          background:
            "linear-gradient(to right, #83008B 0%,#E71953 35%, #FBB927 " +
            this.value +
            "%, #d3d3d3 " +
            this.value +
            "%,#d3d3d3 75%, #d3d3d3 100%)",
        };
      } else {
        return {
          background:
            "linear-gradient(to right, #83008B 0%, #FBB927 " +
            this.value +
            "%, #d3d3d3 " +
            this.value +
            "%, #d3d3d3 100%)",
        };
      }
    },
    getCampaignDetail() {
      return this.getCampaign;
    },
    pageType() {
      return this.getCampaign.type.type;
    },
    getVideo() {
      return this.getCampaignDetail.campaign_detail.introduction_video;
    },
    getdates() {
      let kick_off = this.getCampaignDetail.campaign_detail.kick_off_date_str_2;
      let kick_off_str =
        this.getCampaignDetail.campaign_detail.kick_off_date_str;

      let gold_ball =
        this.getCampaignDetail.campaign_detail.gold_ball_giveaway_date_str_2;
      let gold_ball_str =
        this.getCampaignDetail.campaign_detail.gold_ball_giveaway_date_str;

      let graduation =
        this.getCampaignDetail.campaign_detail
          .graduation_celebration_date_str_2;
      let graduation_str =
        this.getCampaignDetail.campaign_detail.graduation_celebration_date_str;
      let event_end = this.getCampaignDetail.campaign_detail.end_date_str_2;
      let event_end_str = this.getCampaignDetail.campaign_detail.end_date_str;

      let active_event = this.getCampaignDetail.campaign_detail.active_event;
      return {
        kickOff: kick_off,
        kickOffStr: kick_off_str,
        goldBall: gold_ball,
        goldBallStr: gold_ball_str,
        grad: graduation,
        gradStr: graduation_str,
        eventEnd: event_end,
        eventEndStr: event_end_str,
        active: active_event,
      };
    },
  },
  watch: {},
  methods: {
    getImgSrc(src) {
      return require("@/assets/publicDonationPage/timelineEvent/" + src);
    },
    addCalendar(date, dateFor) {
      let dates = moment(date).format("YYYYMMDDTHH");
      // console.log(dates);
      let title = "Wizfit Challenge" + " " + dateFor;
      let url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${dates}/${dates}`;
      // console.log(url);
      window.open(url, "_blank");
      // console.log("add to calendar");
    },
    openVideo() {
      // console.log("video opened");
      this.imageViewer.src = this.getVideo;
      this.imageViewer.flag = true;
    },
    getActiveState() {
      console.log(this.getdates);
      if (this.getdates.kickOff === this.getdates.active) {
        this.kickOff = true;
        if (this.$vuetify.breakpoint.smAndUp) {
          this.value = 10;
        } else {
          this.value = 15;
        }
      } else if (this.getdates.goldBall === this.getdates.active) {
        this.goldBall = true;
        this.value = 38;
      } else if (this.getdates.grad === this.getdates.active) {
        this.graduation = true;
        this.value = 65;
      } else {
        this.endDate = false;
        this.value =0;
      }
    },
  },
  mounted() {
    this.getActiveState();

  },
};
</script>

<style scoped>
.heading {
  font-size: 25px;
  font-family: Roboto Slab;
  font-weight: 600;
  color: #2c1963;
  display: flex;
}
.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.eventTitle {
  min-height: 50px;
  font-family: Roboto Slab;
  font-style: normal;
}
.eventTitleActive {
  min-height: 50px;
  font-family: Roboto Slab;
  font-style: normal;
  color: #38227a;
}
.eventDate {
  border: 1px solid #757575;
  box-sizing: border-box;
  border-radius: 6px;
  color: #757575;
}
.eventDateActive {
  border: 1px solid #757575;
  box-sizing: border-box;
  border-radius: 6px;
  color: #ffffff;
  background: #38227a;
}
.playButtonIcon {
  width: 70px;
  height: 70px;
  position: relative;
  bottom: 40%;
  right: 45%;
  cursor: pointer;
}

/*range slider*/
.slidecontainer {
  width: 100%;
  height: 80px;
}

.slider {
  -webkit-appearance: none;
  width: 80%;
  height: 30px;
  border-radius: 20px;
  background: #d3d3d3;
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 70px;
  height: 60px;
  border: 0;
  background: url("../../../../assets/publicDonationPage/football.svg");
  cursor: pointer;
  background-repeat: no-repeat !important;
  background-size:contain !important;
}

.slider::-moz-range-thumb {
  -webkit-appearance: none;
  width: 70px;
  height: 24px;
  border: 0;
  background: url("../../../../assets/publicDonationPage/football.svg");
  cursor: pointer;
} /*
#myRange {
  background: linear-gradient(to right, #83008B, #E71953, #F7CB01);
  
  -webkit-appearance: none;
}*/
.gifClass {
  max-width: 100px;
  margin-bottom: -20px;
}
.pngClass {
  margin-top: 22px;
  max-width: 60px;
  margin-bottom: 8px;
}
.kickGifClass {
  max-width: 100px;
}
.kickPngClass {
  max-width: 55px;
  margin-top: 40px;
}
.goldPngClass {
  max-width: 70px;
  margin-top: 8px;
}
.goldGifClass {
  max-width: 100px;
  margin-bottom: -22px;
}
.graduationPngClass {
  max-width: 70px;
  margin-top: 10px;
}
.endPngClass {
  max-width: 55px;
  margin-top: 38px;
  margin-bottom: 13px;
}
.endGifClass {
  max-width: 110px;
  margin-bottom: -13px;
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
  .hLine {
    width: 30px;
    height: 3px;
  }
  .slidecontainer {
    width: 365px;
    height: 100%;
    transform: rotate(-270deg);
    margin-top: 165px;
    margin-left: -170px;
  }
  .slider {
    -webkit-appearance: none;
    width: 80%;
    height: 15px;
    border-radius: 20px;
    background: #d3d3d3;
    outline: none;
  }
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    height: 40px;
    border: 0;
    background: url("../../../../assets/publicDonationPage/football.svg");
    cursor: pointer;
    background-size: 40px, 20px;
    background-repeat: no-repeat;
  }

  .slider::-moz-range-thumb {
    -webkit-appearance: none;
    width: 40px;
    height: 15px;
    border: 0;
    background: url("../../../../assets/publicDonationPage/football.svg");
    cursor: pointer;
  }
  


  /*  */
  .eventTitle {
    min-height: 30px;
    font-family: Roboto Slab;
    font-style: normal;
  }
  .eventTitleActive {
    min-height: 30px;
    font-family: Roboto Slab;
    font-style: normal;
    color: #38227a;
  }
  .endPngClass {
    max-width: 55px;
    margin-top: -5px;
  }
  .endGifClass {
    max-width: 100px;
    margin-top: -30px;
    margin-left: -20px;
  }
  .gifClass {
    max-width: 110px;
    margin-left: -20px;
    margin-top: -30px;
  }
  .pngClass {
    max-width: 60px;
    max-height: 60px;
    margin-top: -5px;
  }
  .kickGifClass {
    max-width: 80px;
    margin-left: -10px;
    margin-top: -30px;
  }
  .kickPngClass {
    max-width: 50px;
    margin-top: -10px;
    margin-left: 5px;
  }
  .goldPngClass {
    max-width: 60px;
    margin-top: -10px;
  }
  .goldGifClass {
    max-width: 80px;
    margin-top: -20px;
    margin-left: -10px;
  }
  .graduationPngClass {
    max-width: 60px;
    margin-top: -5px;
  }
  .graduationGifClass {
    max-width: 90px;
    margin-top: -20px;
    margin-bottom: -10px;
    margin-left: -13px;
  }
}
</style>
<style>
.slider::-moz-range-thumb {
 background-size: contain !important;
 background-repeat: no-repeat !important;
 height:60px !important;
}
</style>
